<!--  -->
<template>
  <div style="width: 100%; height: 100%">
    <div style="margin-bottom: 10px; width: 100%">
      <el-date-picker
        v-model="value1"
        type="daterange"
        value-format="yyyy-MM-dd"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        size="mini"
      >
      </el-date-picker>
    </div>
    <div id="myChartAll" style="width: 100%; height: 100%"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    list: Object,
  },
  data() {
    //这里存放数据
    return {
      list2: [],
      value1: [],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    async getList() {
      // let info = await this.$axios.post("cheshia");
      // console.log(info);
      // this.list2 = info.data.data;
      this.drawLine();
    },
    drawLine() {
      var chartDom = document.getElementById("myChartAll");
      var myChart = this.$echarts.init(chartDom);
      var option;

      option = {
        title: { text: "订单总数" },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [10, 20, 40, 50, 70, 100, 101],
            type: "line",
            areaStyle: {},
            // smooth: true,
          },
        ],
      };
      // option.xAxis.data = this.list2.mobile;
      // option.series[0].data = this.list2.member;
      option && myChart.setOption(option);
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.$nextTick(() => {
      this.getList();
    });
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped>
/* #myChart {
  height: 100%;
  width: 100%;
} */
.el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
  width: 100%;
}
</style>