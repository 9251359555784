<!--  -->
<template>
  <div class="index">
    <el-card>
      <!-- 头 -->
      <el-row :gutter="20" style="margin-bottom: 10px">
        <el-col :span="5">
          <el-date-picker
            v-model="value1"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            size="mini"
          >
          </el-date-picker>
        </el-col>
      </el-row>
      <div class="list">
        <div class="char-item"><Echarts /></div>
        <div class="char-item"><EcCom /></div>
        <div class="char-item"><EcOrder /></div>
      </div>
      <div class="list">
        <div class="char-item2"><EcAll /></div>
        <div class="char-item2 item3"><EcClass /></div>
      </div>
    </el-card>
  </div>
</template>
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import Echarts from "@/components/Echarts";
import EcCom from "@/components/EcCom";
import EcOrder from "@/components/EcOrder";
import EcAll from "@/components/EcAll";
import EcClass from "@/components/EcClass";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    Echarts,
    EcCom,
    EcOrder,
    EcAll,
    EcClass,
  },
  data() {
    //这里存放数据
    return {
      value1: "",
      getNav: [],
      getInfo: { info: "" },
    };
  },
  //监听属性 类似于data概念
  computed: {
    info: function () {
      // 通过vuex的getters方法来获取state里面的数据
      return this.$store.state.history;
    },
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    catalogue() {},
    add() {
      this.$store.commit("getroute", "231312");
      console.log(this.$store.state.history);
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped>
.page {
  margin-top: 20px;
}
.title {
  font-size: 18px;
  font-weight: bold;
}
.set-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.set-page-left {
  color: #0000006d;
  font-size: 14px;
  font-weight: 400;
}
.char-item {
  flex: 1;
  height: 260px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 13px;
  margin-right: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
}
.list {
  display: flex;
  /* flex-wrap: wrap; */
}
.trestle {
  width: 100%;
  height: 300px;
}
.item3 {
  flex: 1 !important;
}
.char-item2 {
  flex: 3;
  height: 260px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 13px;
  margin-right: 10px;
  margin-bottom: 10px;
}
</style>